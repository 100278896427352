import { useEffect, useState, useCallback } from "react";

import Layout from "./components/Layout";

import client from "./feathers";

const channelService = client.service("channels");

const { REACT_APP_API } = process.env;

function App() {
  const [user, setUser] = useState(null);
  const [channel, setChannel] = useState(null);
  const [data, setData] = useState({
    subCount: 0,
    monthNumber: 0,
    botEnabled: false,
  });

  const login = async () => {
    try {
      const result = await client.reAuthenticate();
      console.log(result.user);
      setUser(result.user);
    } catch (e) {
      console.log(e);
    }
  };

  const handleChange = (e) => {
    setData((d) => {
      return {
        ...d,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleBotEnable = (e) => {
    setData((d) => {
      return {
        ...d,
        botEnabled: !d.botEnabled,
      };
    });
  };

  const handleSave = async (e) => {
    // console.log(data);

    try {
      const result = await channelService.patch(channel._id, {
        subCount: parseInt(data.subCount),
        monthNumber: parseInt(data.monthNumber),
        botEnabled: data.botEnabled,
      });
      alert("Gespeichert!");
    } catch (e) {
      alert("Fehler!");
    }

    // console.log(result);
  };

  const handleActivate = async (e) => {
    const result = await channelService.patch(channel._id, {
      active: true,
    });
  };

  const getChannel = useCallback(async () => {
    if (!user || user.twitch.broadcasterType !== "partner") {
      return;
    }
    try {
      const result = await channelService.find({
        query: {
          name: user.twitch.login,
        },
      });

      if (result.total === 1) {
        setChannel(result.data[0]);
        setData({
          subCount: result.data[0].subCount,
          monthNumber: result.data[0].monthNumber,
          botEnabled: result.data[0].botEnabled,
        });
      }
      // console.log(result);
    } catch (e) {
      console.log(e);
    }
  }, [user]);

  useEffect(() => {
    login();
  }, []);

  const handleLogout = () => {
    client.logout();
    setUser(null);
  };

  useEffect(() => {
    getChannel();
  }, [getChannel]);

  if (!user) {
    return (
      <Layout>
        <p>
          Twitch hat das neue Partner Plus Programm verkündet, welches
          Streamern, die die Voraussetzungen erfüllen für jeweils ein Jahr die
          Möglichkeit gibt ein 30/70 Share zu erhalten.
        </p>
        <p>
          Um die Voraussetzungen zu erfüllen müssen 3 Monate in Folge 350
          Partner Plus Punkte erzielt werden.
          <br />
          Dabei werden nur bezahlte Subs (keine Gifted Subs) gewertet.
          <br />
          Ein Tier 1 Sub gibt 1 Punkt, ein Tier 2 Sub 2 Punkte und ein Tier 3
          Sub 6 Punkte.
        </p>
        <p>
          Zwar kann der Streamer seinen Fortschritt im Creator Dashboard auf
          Twitch unter Einnahmen überprüfen, allerdings ist dieser Weg recht
          umständlich, und die Zuschauer wissen nicht, wie viel Support noch
          fehlt um das Ziel zu erreichen.
        </p>
        <p>
          Hierfür haben wir das Partner Plus Goal Widget entwickelt.
          <br />
          Verbindet einfach euren Twitch Account und fügt das Widget als Browser
          Source in OBS ein.
          <br />
          Schon habt ihr und eure Zuschauer immer einen Überblick über den
          aktuellen Fortschritt.
        </p>
        <p>
          Es werden nur die relevanten Subs gezählt, und jeweils zum 1. eines
          Monats wird der Zähler wieder zurückgesetzt.
          <br />
          Wurden bis hierhin die 350 Punkte erreicht, wird der Monat um einen
          erhöht.
          <br />
          Hat man 3 Monate in Folge 350 Punkte erreicht, so ist man für das
          Partner Plus Programm für ein Jahr qualifiziert.
        </p>
        <p class="center">
          <br />
          <button
            onClick={() => {
              window.location.href = `${REACT_APP_API}/oauth/twitch`;
            }}
          >
            Login with Twitch
          </button>
        </p>
      </Layout>
    );
  }

  if (user && user.twitch.broadcasterType !== "partner") {
    return (
      <Layout>
        <strong>Willkommen {user.twitch.displayName}</strong>
        <br />
        <br />
        <p>Dieses Tool funktioniert nur für Twitch Partner Accounts!</p>
        <button onClick={() => handleLogout()}>Logout</button>
      </Layout>
    );
  }

  return (
    <Layout>
      <strong>Willkommen {user?.twitch.displayName}</strong>
      <br />
      <br />
      <p>
        <strong>
          Falls euch Fehler auffallen, zum Beispiel Wertungsfehler, sagt mir
          bitte unbedingt auf Discord bescheid: 3l3ktr0l4mpe.
          <br />
          Sollte euch etwas unklar sein, kommt auch gerne auf mich zu.
        </strong>
      </p>
      <p>
        <strong>
          03.10.2023: Bitte nicht den Wert aus der Partner Plus Übersicht bei
          Twitch eintragen. Dieser Wert beinhaltet jederzeit auch schon die
          wiederkehrenden Abos, auch wenn diese noch gar nicht aktiv sind. Würde
          dieser Wert genommen werden, dann wird bei bekanntgabe des Re-Subs der
          Punkt quasi doppelt gezählt! Ihr müsst hier bei Einnahmen den Zeitraum
          entsprechend Einstellen und die Stufe 1, Stufe 2 und Stufe 3 Abos
          manuell zusammenrechnen. Anders geht es leider nicht.
        </strong>
      </p>
      <p>
        <strong>
          25.09.2023: Mehrmonatige Gift-Subs wurden fälschlicher Weise nur im
          ersten Monat als Gift Sub erkannt. Dies hat zu Abweichungen geführt.
          Der Fehler ist mittlerweile behoben und jedes neue Sub wird noch
          einmal im Hintergrund geprüft, ob es ein Gift Sub ist.
        </strong>
      </p>
      <p>
        Um den aktuellen Fortschritt bei Twitch zu erfahren, gehe im
        Creator-Dashboard zum Abschnitt "Einnahmen", ändere das Drop-down-Menü
        zu "Monat" und wähle den entsprechenden Zeitraum aus. Anhand des
        Messwerts "Insgesamt Partner Plus Punkte" für den jeweiligen Monat
        kannst du deinen Fortschritt beim Erreichen der
        Qualifizierungsanforderung von 350 Punkten erkennen.
      </p>
      <p>
        Diesen Wert kannst du initial, oder bei Abweichung hier übernehmen, um
        die Werte anzupassen.
      </p>
      <p>
        <strong>
          WICHTIG: Es KANN zu leichten Abweichungen kommen, da nur Subs erkannt
          werden, die geteilt wurden. Dies ist eine Limitierung der Twitch API.
          <br />
          Wir empfehlen daher, hin und wieder (idealer Weise zum Ende des
          Monats) den aktuellen Stand mit den Twitch Insights abzugleichen.
        </strong>
      </p>
      Aktueller Monat in Folge:{" "}
      <select
        name="monthNumber"
        value={data.monthNumber}
        onChange={handleChange}
      >
        <option value="0">1</option>
        <option value="1">2</option>
        <option value="2">3</option>
      </select>
      <br />
      <br />
      Aktuelle Partner Plus Punkte:{" "}
      <input
        name="subCount"
        onChange={handleChange}
        type="number"
        value={data.subCount}
      />
      <br />
      <br />
      <label>
        <input
          type="checkbox"
          checked={data.botEnabled}
          onChange={handleBotEnable}
        />
        Bot aktivieren
      </label>
      <br />
      <p>
        Der Bot weist den Abonnenten auf seinen Support in Hinsicht auf das
        Partner Plus Ziel hin. Dies soll die Awareness bei den Supportern für
        das Partner Plus Ziel erhöhen.
        <br />
        <strong>
          Bitte dem Bot Mod-Rechte in deinem Channel geben: /mod StreamRTechBot
          (wegen Nachrichtenlimits seitens Twitch die andernfalls gelten)
        </strong>
      </p>
      <br />
      <br />
      <button onClick={handleSave}>Speichern</button>{" "}
      {channel && !channel.active && (
        <button onClick={handleActivate}>Aktivieren</button>
      )}
      <br />
      <br />
      Widget URL (Höhe 80 Pixel):
      <br />
      {channel && (
        <pre>https://streamrtech.de/tools/ppg/?key={channel.key}</pre>
      )}
      <br />
      <br />
      Widget URL (nur Balken, Höhe 30 Pixel):
      <br />
      {channel && (
        <pre>https://streamrtech.de/tools/ppg/?key={channel.key}&skin=1</pre>
      )}
      <br />
      <p>
        Die Breite kann nach Wunsch angepasst werden. Das Widget füllt immer die
        gesamte zur Verfügung stehende Breite aus.
      </p>
      <br />
      <p>Die Farben können mit HTML HEX Color Codes angepasst werden:</p>
      {channel && (
        <pre>
          https://streamrtech.de/tools/ppg/?key={channel.key}
          &color1=ff0000&color2=00ff00
        </pre>
      )}
      <p class="center">
        <br />
        <button onClick={() => handleLogout()}>Logout</button>
      </p>
    </Layout>
  );
}

export default App;
